jQuery(function($) {

  function showHideNav() {
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.site-header').outerHeight();

    $(window).on('scroll', function(e) {
      didScroll = true;
    });

    setInterval(function() {
      if(didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);

    function hasScrolled() {
      var st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if(Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > lastScrollTop && st > navbarHeight) {
          // Scroll Down
          $('.site-header').removeClass('nav-down').addClass('nav-up');
      } else {
          // Scroll Up
          if(st + $(window).height() < $(document).height()) {
              $('.site-header').removeClass('nav-up').addClass('nav-down');
          }
      }

      lastScrollTop = st;
    }
  }

  function mobileNav() {
    // Get the dimensions of the viewport
    var width = window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;

    //dropdown
    $('.menu-item-has-children > a').unbind('click');

    if (width < 768) {

      $('.main-navigation .menu-item-has-children:not(.opened) > a').click(function(e){
        e.preventDefault();
        if ($(this).parent().hasClass('opened')) {
          $(this).parent().removeClass('opened');
          $(this).siblings('.sub-menu').slideUp();

        }
        else {
          $(this).parent().addClass('opened');
          $(this).siblings('.sub-menu').slideDown();
        }
      });
    }
    else {
      $('.sub-menu').removeAttr('style');
      $('.main-navigation .menu-item-has-children > a').unbind('click');
      $('.main-navigation .menu-item-has-children').removeClass('opened');
      $('#primary-menu').removeAttr('style');
    }
  }

  function fixedBodyClassOnMobileNav() {
    // Get the dimensions of the viewport
    var width = window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;

    if (width < 768) {
      $('body').removeClass('body__fixed');
      $('.body__overlay').removeClass('visible');

      $('.menu-toggle').click(function() {
        if( $('.main-navigation').hasClass('toggled') ) {
          $('body').addClass('body__fixed');
          $('.body__overlay').addClass('visible');
        } else {
          $('body').removeClass('body__fixed');
          $('.body__overlay').removeClass('visible');
        }
      });
    } else {
      $('body').removeClass('body__fixed');
      $('.body__overlay').removeClass('visible');
    }
  }

  function respond() {
    //Put all responsive functions here
    mobileNav();
    fixedBodyClassOnMobileNav();
  }

  $(document).ready(function() {
    respond();
    showHideNav();
  });

  $(window).on('load', function() {

  });

  $(window).on('resize', function() {
    respond();
  });

  $(window).on('scroll', function() {

  });
}(jQuery));