/**
 * Site Entry Point
 */

 // Fonts


// Global Javascript
import './js/navigation';
import './js/skip-link-focus-fix';
import './../node_modules/slick-carousel/slick/slick.min.js';
import './../node_modules/jquery-modal/jquery.modal.min.js';
import './../node_modules/jquery-match-height/dist/jquery.matchHeight-min.js';
import './js/main';

// Global Styles
import './../node_modules/slick-carousel/slick/slick.scss';
import './../node_modules/jquery-modal/jquery.modal.min.css';
import './scss/index.scss';